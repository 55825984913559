import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';

import appConfig from '@config/app';
import { LoadingBlock } from '@shared/utils';
import { stringEscape } from '@shared/helpers';
import { store, persistor } from './store';

const LazyAppRouter = lazy(() => import('@app/AppRouter'));

const urlPropagate = new RegExp(`^${stringEscape(appConfig.apiUrl)}`);

if (appConfig.sentry.env !== 'local') {
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    environment: appConfig.sentry.env,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ 'localhost', urlPropagate ],
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to
    // 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate
    // to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}

const App = () => (
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<LoadingBlock />}>
          <LazyAppRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default App;
