// CountrySaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest } from '@services/RequestService';
import { fetchCountriesSagaAction, fetchCountriesSucceededReduxAction, fetchCountriesFailedReduxAction } from './CountryAction';

function* fetchCountries() {
  try {
    const request = yield call(getRequest, 'admin/countries');
    const response = yield request.$promise;
    yield put(fetchCountriesSucceededReduxAction({ countries: response.data }));
  } catch (error) {
    yield put(fetchCountriesFailedReduxAction(error));
  }
}

function* watchFetchCountries() {
  yield takeLatest(fetchCountriesSagaAction, fetchCountries);
}

export default watchFetchCountries;
