/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getUsersSagaAction = createAction('APZ_USER_GET_USERS');
export const getUsersSucceededReduxAction = createAction('APZ_USER_GET_USERS_SUCCEEDED_REDUX');

export const getCodeSagaAction = createAction('APZ_USER_GET_CODE');
export const getCodeSucceededReduxAction = createAction('APZ_USER_GET_CODE_SUCCEEDED_REDUX');

export const banUserSagaAction = createAction('APZ_USER_BAN_USERS');

export const singleViewSagaAction = createAction('APZ_SINGLE_VIEW_USERS');

export const deactivateAffiliateSagaAction = createAction('APZ_USER_DEACT_USERS');

export const getBannedUsersSagaAction = createAction('APZ_USER_GET_BANNED_USERS');
export const getBannedUsersSucceededReduxAction = createAction('APZ_USER_GET_BANNED_USERS_SUCCEEDED_REDUX');

export const getAdminUsersSagaAction = createAction('APZ_USER_GET_ADMIN_USERS');
export const getAdminUsersSucceededReduxAction = createAction('APZ_USER_GET_ADMIN_USERS_SUCCEEDED_REDUX');

export const createAdminUserSagaAction = createAction('APZ_USER_CREATE_ADMIN_USER');
export const createAdminUserFailedReduxAction = createAction('APZ_USER_CREATE_ADMIN_USER_FAILED');

export const updateAdminUserSagaAction = createAction('APZ_USER_UPDATE_ADMIN_USER');
export const updateAdminUserFailedReduxAction = createAction('APZ_USER_UPDATE_ADMIN_USER_FAILED');

export const updateAffiliateSagaAction = createAction('APZ_USER_UPDATE_AFFILIATE');
export const updateAffiliateFailedReduxAction = createAction('APZ_USER_UPDATE_AFFILIATE_FAILED');

export const createAffiliateSagaAction = createAction('APZ_USER_CREATE_AFFILIATE');
export const createAffiliateFailedReduxAction = createAction('APZ_USER_CREATE_AFFILIATE_FAILED');

export const deleteAdminUserSagaAction = createAction('APZ_USER_DELETE_ADMIN_USER');

export const getMerchantsSagaAction = createAction('APZ_USER_GET_MERCHANTS');
export const getMerchantsSucceededReduxAction = createAction('APZ_USER_GET_MERCHANTS_SUCCEEDED_REDUX');

export const getAffiliatesSagaAction = createAction('APZ_USER_GET_AFFILIATES');
export const getAffiliatesSucceededReduxAction = createAction('APZ_USER_GET_AFFILIATES_SUCCEEDED_REDUX');

export const saveUserTabReduxAction = createAction('APZ_USER_SAVE_USER_TAB_REDUX');

export const clearUserErrorsOnLoadSucceededAction = createAction('APZ_USER_CLEAR_ERRORS_ON_LOAD_SUCCEEDED_REDUX');

export const saveTagsSagaAction = createAction('APZ_SAVE_USER_TAGS');
export const getTagsSagaAction = createAction('APZ_GET_USER_TAGS');
export const getTagsSucceededReduxAction = createAction('APZ_GET_USER_TAGS_SUCCEEDED_REDUX');
export const searchUserSagaAction = createAction('APZ_SEARCH_USER');
export const searchUserSucceededReduxAction = createAction('APZ_SEARCH_USER_SUCCEEDED_REDUX');
export const assignUserTagsSagaAction = createAction('APZ_ASSIGN_USER_TAGS');
export const deleteUserTagsSagaAction = createAction('APZ_DELETE_USER_TAGS');
