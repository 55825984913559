import moment from 'moment';
import {
  isEmpty,
  snakeCase, mapKeys,
} from 'lodash';

const currencyFormat = (value) => {
  const parsedFloat = parseFloat(value);
  const currencySign = '$ ';
  return currencySign + parsedFloat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

// Replace underscore with spaces and capitalize words
const humanize = (str) => {
  const removedUnderscore = str.split('_');
  const capitalizedWords = removedUnderscore.map(
    (word) => word.slice(0, 1).toUpperCase() + word.slice(1),
  );
  const joinedWords = capitalizedWords.join(' ');
  // Capitalize words that has slash symbol
  const removeSlashed = joinedWords.split('/');
  const mappedSlashed = removeSlashed.map((word) => word.slice(0, 1).toUpperCase() + word.slice(1));
  // Capitalize words that has dash symbol
  const joinedMappedWords = mappedSlashed.join('/');
  const removeDashed = joinedMappedWords.split('-');
  const mappedDashed = removeDashed.map((word) => word.slice(0, 1).toUpperCase() + word.slice(1));
  return mappedDashed.join('-');
};

const timestampToDate = (date) => moment(date).format('MMM. D, YYYY');

const getDisplayName = (data) => {
  const user = mapKeys(data, (val, key) => snakeCase(key));
  if (!isEmpty(user.display_name)) {
    return user.display_name;
  }
  return user.full_name;
};

const hex = (c) => {
  const v = `0${c.charCodeAt(0).toString(16)}`;
  return `\\x${v.substr(v.length - 2)}`;
};

const stringEscape = (s) => (s ? s
  .replace(/\\/g, '\\\\')
  .replace(/\n/g, '\\n')
  .replace(/\t/g, '\\t')
  .replace(/\v/g, '\\v')
  .replace(/'/g, "\\'")
  .replace(/"/g, '\\"')
  // eslint-disable-next-line
  .replace(/[\x00-\x1F\x80-\x9F]/g, hex) : s);

export {
  currencyFormat,
  humanize,
  timestampToDate,
  getDisplayName,
  stringEscape,
};
