import { handleActions } from 'redux-actions';
import {
  fetchCountriesSagaAction,
  fetchCountriesSucceededReduxAction,
  fetchCountriesFailedReduxAction,
} from './CountryAction';

const defaultState = {
  loading: false,
  countries: [],
  errors: {},
};

const reducer = handleActions({
  [fetchCountriesSagaAction](state) {
    return { ...state, loading: true, errors: {} };
  },
  [fetchCountriesSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      countries: payload.countries,
      loading: false,
    };
  },
  [fetchCountriesFailedReduxAction](state, { payload }) {
    return {
      ...state,
      errors: payload,
      loading: false,
    };
  },
}, defaultState);

export default reducer;
