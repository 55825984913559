import { combineReducers } from 'redux';
import AuthReducer from '@reducers/auth/AuthReducer';
import LoadingReducer from '@reducers/loading/LoadingReducer';
import MenuReducer from '@reducers/menu/MenuReducer';
import SettingsReducer from '@reducers/settings/SettingsReducer';
import DashboardReducer from '@reducers/dashboard/DashboardReducer';
import UserReducer from '@reducers/user/UserReducer';
import ReportReducer from '@reducers/report/ReportReducer';
import SystemSettingsReducer from '@reducers/system-settings/SystemSettingsReducer';
import FeedReducer from '@reducers/feed/FeedReducer';
import LocationReducer from '@reducers/location/LocationReducer';
import AdvertisementReducer from '@reducers/advertisement/AdvertisementReducer';
import GlobalProfileReducer from '@reducers/global-profile/GlobalProfileReducer';
import EngagementRewardReducer from '@reducers/engagement-reward/EngagementRewardReducer';
import countryReducer from '@reducers/country/CountryReducer';

const reducers = combineReducers({
  Auth: AuthReducer,
  Loading: LoadingReducer,
  Menu: MenuReducer,
  Settings: SettingsReducer,
  Dashboard: DashboardReducer,
  User: UserReducer,
  Report: ReportReducer,
  SystemSettings: SystemSettingsReducer,
  Feed: FeedReducer,
  Location: LocationReducer,
  Advertisement: AdvertisementReducer,
  GlobalProfile: GlobalProfileReducer,
  EngagementReward: EngagementRewardReducer,
  Country: countryReducer,
});

export default reducers;
