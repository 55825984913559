export default {
  // prevent redirection if 401 is received
  whitelistUrl: [
    '/login',
  ],
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  locationIQ: {
    apiUrl: 'https://us1.locationiq.com/v1',
    api_key: '1bef19e5b666b2',
  },
  google: {
    apiUrl: process.env.REACT_APP_GOOGLE_MAPS_PROXY_URL,
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    env: process.env.REACT_APP_ENV ?? 'local',
  },
  env: process.env.REACT_APP_ENV ?? 'local',
};
