/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';

import {
  getRequest, postRequest, putRequest, deleteRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
  getUsersSucceededReduxAction,
  getBannedUsersSucceededReduxAction,
  getAdminUsersSucceededReduxAction,
  createAdminUserFailedReduxAction,
  updateAdminUserFailedReduxAction,
  getMerchantsSucceededReduxAction,
  getTagsSucceededReduxAction,
  searchUserSucceededReduxAction,
  getAffiliatesSucceededReduxAction,
  getCodeSucceededReduxAction,
} from './UserAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getCode({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/affiliate/generate', { queryParams: data });
    const response = yield request.$promise;
    yield put(getCodeSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getUsers({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/users', { queryParams: data });
    const response = yield request.$promise;
    yield put(getUsersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getAffiliates({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/affiliates', { queryParams: data });
    const response = yield request.$promise;
    yield put(getAffiliatesSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getBannedUsers({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/users/banned', { queryParams: data });
    const response = yield request.$promise;
    yield put(getBannedUsersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getAdminUsers({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/admins', { queryParams: data });
    const response = yield request.$promise;
    yield put(getAdminUsersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* banUser({ payload }) {
  const { id, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/users/${id}/ban`);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);
    if (onError) {
      onError();
    }
  }
}

export function* singleView({ payload }) {
  const {
    id, onSuccess, onError, year,
  } = payload;
  try {
    const request = yield call(postRequest, `admin/affiliate/details/${id}&year=${year}`);
    const response = yield request.$promise;

    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    handleError(error);
    if (onError) {
      onError();
    }
  }
}

export function* deactivateAffiliate({ payload }) {
  const {
    id, status, onSuccess, onError,
  } = payload;
  try {
    const request = yield call(postRequest, `admin/affiliate/status?id=${id}&status=${status}`, payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);
    if (onError) {
      onError();
    }
  }
}

export function* createAffiliate({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/affiliates', payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { response } = error;
    if (onError) {
      onError(error);
    }
    if (response && response.status === 422) {
      yield put(createAdminUserFailedReduxAction(response.data.errors));
      return;
    }
    handleError(error);
  }
}

export function* createAdminUser({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/admins', payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { response } = error;
    if (onError) {
      onError(error);
    }
    if (response && response.status === 422) {
      yield put(createAdminUserFailedReduxAction(response.data.errors));
      return;
    }
    handleError(error);
  }
}

export function* updateAdminUser({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(putRequest, `admin/admins/${payload.data.id}`, payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { response } = error;
    if (onError) {
      onError(error);
    }
    if (response && response.status === 422) {
      yield put(updateAdminUserFailedReduxAction(response.data.errors));
      return;
    }
    handleError(error);
  }
}

export function* deleteAdminUser({ payload }) {
  try {
    const request = yield call(deleteRequest, `admin/admins/${payload.id}`);
    yield request.$promise;

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    handleError(error);
  }
}

export function* getMerchants({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/merchants', { queryParams: data });
    const response = yield request.$promise;
    yield put(getMerchantsSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* createTags({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/tags', payload.data);
    const response = yield request.$promise;
    yield put(getTagsSucceededReduxAction(response.data));
    onSuccess();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    handleError(error);
  }
}

export function* getAllUserTags({ payload }) {
  const { onSuccess } = payload;
  try {
    const request = yield call(getRequest, 'admin/tags');
    const response = yield request.$promise;
    yield put(getTagsSucceededReduxAction(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);
  }
}
export function* searchUser({ payload }) {
  const { data, onSuccess } = payload;

  try {
    const request = yield call(getRequest, 'admin/users', { queryParams: data });
    const response = yield request.$promise;
    yield put(searchUserSucceededReduxAction(response.data));
    onSuccess();
  } catch (error) {
    handleError(error);
  }
}

export function* assignUserTags({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/user/tags', payload.data);
    yield request.$promise;
    onSuccess();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    handleError(error);
  }
}

export function* deleteUserTag({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/user/tags/remove', payload.data);
    yield request.$promise;
    onSuccess();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    handleError(error);
  }
}
