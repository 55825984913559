import { handleActions, combineActions } from 'redux-actions';

import {
  getUsersSucceededReduxAction,
  getBannedUsersSucceededReduxAction,
  getAdminUsersSucceededReduxAction,
  getAffiliatesSucceededReduxAction,
  getCodeSucceededReduxAction,
  createAdminUserFailedReduxAction,
  createAffiliateSagaAction,
  createAffiliateFailedReduxAction,
  updateAffiliateSagaAction,
  updateAffiliateFailedReduxAction,
  createAdminUserSagaAction,
  updateAdminUserFailedReduxAction,
  updateAdminUserSagaAction,
  getMerchantsSucceededReduxAction,
  saveUserTabReduxAction,
  clearUserErrorsOnLoadSucceededAction,
  getTagsSucceededReduxAction,
  searchUserSucceededReduxAction,
} from './UserAction';

// reducers
const defaultState = {
  users: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  bannedUsers: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  adminUsers: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  merchants: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  errors: {},
  activeUserTab: 'all',
  userTags: {},
  searchResults: {},
};

const reducer = handleActions({
  [getUsersSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      users: payload,
    };
  },
  [getCodeSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      users: payload,
    };
  },
  [getAffiliatesSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      users: payload,
    };
  },
  [getBannedUsersSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      bannedUsers: payload,
    };
  },
  [getAdminUsersSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      adminUsers: payload,
    };
  },
  [getTagsSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      userTags: payload,
    };
  },
  [searchUserSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      searchResults: payload,
    };
  },
  [combineActions(createAdminUserSagaAction, updateAdminUserSagaAction)](state) {
    return { ...state, errors: {} };
  },
  [combineActions(createAdminUserFailedReduxAction,
    updateAdminUserFailedReduxAction)](state, { payload }) {
    return { ...state, errors: payload };
  },
  [combineActions(createAffiliateSagaAction, updateAffiliateSagaAction)](state) {
    return { ...state, errors: {} };
  },
  [combineActions(createAffiliateFailedReduxAction,
    updateAffiliateFailedReduxAction)](state, { payload }) {
    return { ...state, errors: payload };
  },
  [getMerchantsSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      merchants: payload,
    };
  },
  [saveUserTabReduxAction](state, { payload }) {
    return {
      ...state,
      activeUserTab: payload,
    };
  },
  [clearUserErrorsOnLoadSucceededAction](state) {
    return { ...state, errors: {} };
  },
}, defaultState);

export default reducer;
